<template>
  <div class="overview-container">
    <div class="overview-inner-container">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6" class="overview-inner-container-text">
            <div class="overview-title">
              Behalte <br/> den Überblick
            </div>
            <div class="mb-4">
              Verwalten und verfolgen Sie Ihre Flotte aus der Ferne. Überprüfen Sie den Energiestand.
            </div>
            <div class="overview-benefits">
              <v-img src="../../../public/images/icons/SR_ICON_Location.svg"/>
              Just in Time - Geofencing & Lokalisierung aller Fahrzeuge
            </div>
            <div class="overview-benefits">
              <v-img src="../../../public/images/icons/SR_ICON_Energie.svg" style="margin-bottom: -4px"/>
              Energiestand & Motoranalyse
            </div>
            <div class="overview-benefits">
              <v-img src="../../../public/images/icons/SR_ICON_Verwaltung.svg"/>
              ON/OFF Verwaltung
            </div>
            <div class="overview-benefits">
              <v-img src="../../../public/images/icons/SR_ICON_Aufgaben.svg"/>
              Aufgaben automatisieren
            </div>
            <div class="overview-benefits">
              <v-img src="../../../public/images/icons/SR_ICON_Umsatz.svg"/>
              Umsatzdaten mit API für Buchhaltung
            </div>
            <div class="overview-benefits">
              <v-img src="../../../public/images/icons/SR_ICON_Management.svg"/>
              Management & Monitoring Analysen
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="overview-management">
            <v-img src="../../../public/images/Flottenmanagement_UI.png" contain/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="overview-inner-container">
      <v-container>
        <v-row>
          <v-col cols="12" order="2" sm="12" order-sm="2" md="7" order-md="1" lg="6" order-lg="1" order-xl="1"
                 class="overview-app-container">
            <v-img id="app_1" src="../../../public/images/Superrent_App_Mockup_01.png"/>
            <v-img id="app_2" @mouseenter="animateApp()"
                   src="../../../public/images/Superrent_App_Mockup_02.png"/>
            <v-img id="app_3" src="../../../public/images/Superrent_App_Mockup_03.png"/>
          </v-col>
          <v-col cols="12" order="1" sm="12" order-sm="1" md="5" order-md="2" lg="6" order-lg="2" order-xl="2"
                 class="overview-inner-container-text">
            <div class="mb-1"><small>Nutzer App</small></div>
            <div class="overview-title">
              SCHAFFE EIN <br/> SUPER DIGITALES <br/> ERLEBNIS
            </div>
            <div class="mb-4" style="max-width: 600px">
              Nutzer können jedes mit einem Supertracker versehene Fahrzeug buchen und die Fahrt auf dem Smartphone
              bezahlen, beginnen & beenden. Die in deinem Design erstellte Nutzer-APP ist neben deinen Fahrzeugen eines
              der wichtigsten Bestandteile deines Mobilitätsangebots. Deine CI & dein CD kannst du selbstständig in die
              Lösung einbringen & bearbeiten.
            </div>
            <div class="overview-benefits">
              SaaS Solution ab € 9,90 / Monat/ Stück (monatlich kündbar/einmalige Aktivierungsgebühr € 19,90)
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiCheckboxBlankCircle } from '@mdi/js'
export default {
  name: "Overview",
  data: () => ({
    icons: {
      circle: mdiCheckboxBlankCircle
    },
  }),
  methods: {
    animateApp() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        const app1 = document.getElementById('app_1');
        const app2 = document.getElementById('app_2');
        const app3 = document.getElementById('app_3');
        app2.classList.add('overview-transformed');
        setTimeout(() => {
          app1.classList.add('overview-transformed');
          app3.classList.add('overview-transformed');
        }, 400);
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/variables.scss";
  @import "../../assets/scss/layout.scss";
  .overview-container {
    margin-top: 20px;
    .overview-inner-container {
      margin-bottom: 40px;
      background: #333333 0 0 no-repeat padding-box;
      border-radius: 30px;
      color: white;
      font-weight: normal;
      padding-top: 50px;
      padding-bottom: 50px;
      .overview-inner-container-text {
        padding-left: 100px;
        @include MQ(XS) {
          padding-left: 15px;
        }
        @include MQ(S) {
          padding-left: 50px;
        }
        @include MQ(M) {
          padding-left: 25px;
        }
        @include MQ(L) {
          padding-left: 25px;
        }
      }
      .overview-title {
        color: $primary;
        text-transform: uppercase;
        font-size: 35px;
        line-height: 38px;
        margin-bottom: 15px;
      }
      .overview-benefits {
        margin-bottom: 15px;
        font-weight: 200;
        .v-image {
          width: 25px;
          max-width: 25px;
          margin-bottom: -8px;
          margin-right: 5px;
          display: inline-block;
        }
      }
      .overview-management {
        display: flex;
        justify-content: center;
        align-items: center;
        .v-image {
          max-width: 550px;
          @include MQ(XS) {
            max-width: 300px;
          }
        }
      }
      .overview-app-container {
        position: relative;
        .v-image {
          width: 400px;
          display: inline-block;
        }
        #app_1, #app_2, #app_3 {
          position: absolute;
          top:50%;
          left:50%;
          transform:translate(-50%, -50%);
        }
        #app_1, #app_3 {
          display: none;
        }
        #app_1 {
          z-index: 1;
          top: calc(50% + 20px);
          left: calc(50% - 125px);
        }
        #app_2 {
          transition: width .5s ease-in-out;
          width: 600px;
          cursor: pointer;
          z-index: 2;
        }
        #app_3 {
          z-index: 3;
          top: calc(50% - 20px);
          left: calc(50% + 125px);
        }
        .overview-transformed {
          width: 400px !important;
          display: inline-block !important;
        }
        @include MQ(S) {
          height: 260px;
          #app_1, #app_2, #app_3 {
            width: 400px !important;
            display: inline-block !important;
          }
        }
        @include MQ(XS) {
          height: 260px;
          #app_1, #app_2, #app_3 {
            width: 200px !important;
            display: inline-block !important;
          }
          #app_1 {
            z-index: 1;
            top: calc(50% + 20px);
            left: calc(50% - 55px);
          }
          #app_3 {
            z-index: 3;
            top: calc(50% - 20px);
            left: calc(50% + 55px);
          }
        }
      }
    }
  }
</style>
